<template>
  <b-card class="forget-body mb-5">
    <b-card class="mb-5" v-if="done">
      <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.REMOVE_SUPPRESSION_DONE')}}</h3>
    </b-card>

    <b-card class="forget-body mb-5" v-if="!done">
      <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.REMOVE_SUPPRESSION')}}</h3>
      <div class="text-center">{{$t('MEMBER.INFO_REMOVE_SUPPRESSION')}}</div>


      <form class="forget-form" @submit.prevent="onSubmit">
        <b-form-input type="email" v-model="email" class="mb-12 mt-4" :placeholder="$t('MEMBER.EMAIL')" />
        <div class="d-flex align-items-center justify-content-between mb-12 mt-12">
          <b-button class="btn-login" variant="primary" type="submit"
            >{{$t('COMMON.CONTINUE')}}</b-button
          >
        </div>
      </form>
    </b-card>
  </b-card>
</template>

<style lang="scss" scoped>
.forget-body {
  max-width: 500px;
  width: 100%;
  .forget-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import messageParent from '@/core/services/messageParent';
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  mixins: [toasts],
  name: 'RemoveSuppression',
  data() {
    return {
      done: false,
      email: null
    };
  },
  methods: {
   
    async onSubmit() {
      
      const res = await axios.post('/email/remove-suppression', {
        email: this.email
      });

      if (res.status === 204) {
        this.done = true;
        setTimeout(() => {
          this.done = false;
          this.email = '';
        }, 2000);
        this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.SUPPRESSION_REMOVED'))
      } else
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_REMOVE_SUPPRESSION'))
    },
    
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted(){
    
    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  }
};
</script>
